// material
import IconButton from '@/components/shared/iconButton';
import { Clear, Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
// default
import PropTypes from 'prop-types';
// style
import useStyles from './style';

export default function SearchInput(props) {
	const { classes } = useStyles();

	const [value, setValue] = React.useState(props.value);

	const handleChange = (value) => {
		setValue(value);
		props.handleChange(value);
	};

	const InputProps = { className: classes.inputFieldWrap };

	if (value) {
		InputProps.endAdornment = (
			<InputAdornment position="end">
				<IconButton
					onClick={() => handleChange('')}
					icon={<Clear color="inherit" fontSize="small" />}
				/>
			</InputAdornment>
		);
	}

	if (props.useIcon) {
		InputProps.startAdornment = (
			<InputAdornment position="start">
				<Search />
			</InputAdornment>
		);
	}

	React.useEffect(() => {
		if (props.value !== value) setValue(props.value);
	}, [props.value]);

	return (
		<TextField
			type="text"
			InputProps={InputProps}
			inputProps={{ className: classes.noColor }}
			placeholder={props.placeholder}
			fullWidth={props.fullWidth}
			value={value}
			onChange={(e) => handleChange(e.target.value)}
			onKeyDown={props.handleKeyDown}
		/>
	);
}

SearchInput.propTypes = {
	value: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleKeyDown: PropTypes.func,
	fullWidth: PropTypes.bool,
	useIcon: PropTypes.bool,
	placeholder: PropTypes.string,
};

SearchInput.defaultProps = {
	value: '',
	fullWidth: true,
	useIcon: false,
	placeholder: 'Search Blogs',
};
