import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { textValues } from './constants';

// component
import SearchDropdown from '@/components/widgets/searchDropdown';
import SearchFilter from '@/components/widgets/searchFilter';
import SearchInput from '@/components/widgets/searchInput';

// material
import { Box, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function SearchFieldGroup(props) {
	const { classes, cx } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	const renderDropdown = () => {
		return (
			<Box className={classes.filterWrap}>
				<SearchDropdown
					value={props.dropdownValue}
					handleChange={props.handleDropdownChange}
					options={props.dropdownOptions}
					disabled={props.disableDropdown}
				/>
			</Box>
		);
	};

	const renderInput = () => {
		return (
			<Box className={classes.inputWrap}>
				<SearchInput
					useIcon={props.useIconOnInput}
					placeholder={props.inputPlaceholder}
					value={props.inputValue}
					handleChange={props.handleInputChange}
					handleKeyDown={props.handleKeyDown}
				/>
			</Box>
		);
	};

	const renderFilters = () => {
		return (
			<Box className={cx(classes.filterWrap, classes.modalFilterWrap)}>
				<SearchFilter
					formData={props.filterData}
					uiSchema={props.filterUiSchema}
					controlSchema={props.filterControlSchema}
					filterApplied={props.filterApplied}
					handleFilterChange={props.handleFilterChange}
					handleApplyFilter={props.handleApplyFilter}
					handleClearFilter={props.handleClearFilter}
					modalOpen={props.modalOpen}
					toggleModalOpen={props.toggleModalOpen}
				/>
			</Box>
		);
	};

	const renderClearFilter = () => {
		return (
			<Box className={classes.clearFilterWrap}>
				<Typography
					className={cx(
						classes.clearFilterText,
						props.filterApplied ? '' : classes.hidden
					)}
					component="span"
					onClick={props.handleClearFilter}
				>
					{textValues.clearFilterText}
				</Typography>
			</Box>
		);
	};

	if (isMobile && props.useDropdown && props.useFilters) {
		return (
			<Box>
				{renderInput()}
				<Box className={classes.mobileFilterWrap}>
					<Box className={classes.mobileDropdownWrap}>{renderDropdown()}</Box>
					<Box className={classes.mobileClearFilterWrap}>
						{renderClearFilter()}
					</Box>
					<Box className={classes.mobileFiltersWrap}>{renderFilters()}</Box>
				</Box>
			</Box>
		);
	}

	return (
		<Box>
			<Box className={classes.searchFieldGroupWrap}>
				{props.useDropdown && renderDropdown()}
				{renderInput()}
				{props.useFilters && renderFilters()}
			</Box>
			{props.useFilters && renderClearFilter()}
		</Box>
	);
}

SearchFieldGroup.propTypes = {
	// input field
	useIconOnInput: PropTypes.bool,
	inputPlaceholder: PropTypes.string,
	inputValue: PropTypes.string,
	handleInputChange: PropTypes.func,
	handleKeyDown: PropTypes.func,

	// dropdown field
	useDropdown: PropTypes.bool,
	disableDropdown: PropTypes.bool,
	dropdownValue: PropTypes.string,
	dropdownOptions: PropTypes.array,
	handleDropdownChange: PropTypes.func,

	// filter field
	useFilters: PropTypes.bool,
	filterData: PropTypes.object,
	filterApplied: PropTypes.bool,
	handleFilterChange: PropTypes.func,
	handleApplyFilter: PropTypes.func,
	handleClearFilter: PropTypes.func,
	filterUiSchema: PropTypes.object,
	filterControlSchema: PropTypes.object,
	modalOpen: PropTypes.bool,
	toggleModalOpen: PropTypes.func,
};

SearchFieldGroup.defaultProps = {
	useDropdown: false,
	useFilters: false,
};
