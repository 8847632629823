// Material
import { IconButton as MuiIconButton, Tooltip } from '@mui/material';
// Styles
import useStyles from './style';

export default function IconButton({
	icon,
	onClick,
	color,
	size,
	disabled,
	label,
	tooltipPlacement,
	variant,
	...props
}) {
	const { classes } = useStyles({ color, variant });
	const buttonDisabled = disabled;

	const renderIconButton = () => (
		<MuiIconButton
			className={classes.root}
			disabled={buttonDisabled}
			size={size}
			onClick={onClick}
			{...props}
		>
			{icon}
		</MuiIconButton>
	);

	if (label && tooltipPlacement) {
		return (
			<Tooltip title={label} placement={tooltipPlacement}>
				{renderIconButton()}
			</Tooltip>
		);
	}
	return renderIconButton();
}

IconButton.defaultProps = {
	color: 'default',
	size: 'medium',
	disabled: false,
	tooltipPlacement: 'top',
	variant: 'normal',
};
