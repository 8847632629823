import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	searchWrap: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(3),
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			gap: theme.spacing(0),
		},
	},
	filterIconWrap: {
		display: 'flex',
		width: '50px',
		height: '35px',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'flex-end',
		},
	},
	appliedFilterIcon: {
		color: '#78A5FF',
		position: 'absolute',
		right: '0px',
	},
	appliedFilterSubIcon: {
		position: 'absolute',
		right: '0px',
		fontSize: '14px',
		color: theme.palette.success.main,
		background: 'white',
		borderRadius: '50%',
		[theme.breakpoints.down('sm')]: {
			fontSize: '10px',
		},
	},
	filterIcon: {
		color: '#78A5FF',
	},
	clearFilterText: {
		color: theme.palette.primary.main,
	},
	dialogPaper: {
		backgroundColor: theme.palette.common.white,
		borderRadius: '12px',
		padding: theme.spacing(3),
		width: '100%',
		boxShadow: 'none',
		overflowY: 'unset',
	},
	headerIconWrap: {
		margin: '10px',
		position: 'absolute',
		top: 0,
		right: 0,
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: theme.spacing(3, 0),
	},
	modalFilterIcon: {
		color: '#78A5FF',
		marginRight: theme.spacing(1),
		verticalAlign: 'bottom',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(2),
		gap: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse',
		},
	},
	button: {
		borderRadius: '33px',
		padding: '5px 30px',
	},
	hidden: {
		visibility: 'hidden',
	},
	textCenter: {
		textAlign: 'center',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
}));

export default useStyles;
