import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	searchDropdownWrap: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1),
	},
	dropdownContainer: {
		display: 'flex',
		height: '100%',
		gap: theme.spacing(1),
	},
	dropdownWrap: {
		margin: theme.spacing(0),
		width: '150px',
		[theme.breakpoints.down('sm')]: {
			width: '100px',
		},
	},
	selectMenuPaper: {
		maxHeight: '275px',
	},
	iconWrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '47px',
		height: '47px',
		borderRadius: '50%',
		backgroundColor: '#98BAFF',
		color: theme.palette.common.white,
	},
	icon: {
		width: '32px',
		height: '32px',
	},
	select: {
		padding: theme.spacing(1),
		fontSize: theme.typography.h6.fontSize,
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

export default useStyles;
