import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	searchFieldGroupWrap: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			gap: theme.spacing(1),
		},
	},
	filterWrap: {
		display: 'flex',
		height: '100%',
		gap: theme.spacing(1),
	},
	inputWrap: {
		width: '100%',
	},
	modalFilterWrap: {
		marginTop: '5px',
	},
	clearFilterText: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
	},
	hidden: {
		visibility: 'hidden',
	},
	clearFilterWrap: {
		textAlign: 'center',
		marginTop: theme.spacing(1),
	},
	mobileFilterWrap: {
		marginTop: theme.spacing(1),
		display: 'flex',
	},
	mobileDropdownWrap: {
		flexBasis: '33%',
	},
	mobileClearFilterWrap: {
		minWidth: '33%',
	},
	mobileFiltersWrap: {
		marginLeft: 'auto',
	},
}));

export default useStyles;
