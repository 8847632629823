import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	inputFieldWrap: {
		borderRadius: '37px',
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		backgroundColor: theme.palette.common.white,
	},
	noColor: {
		'&:-webkit-autofill': {
			WebkitBoxShadow: `0 0 0 100px ${theme.palette.common.white} inset`,
		},
	},
}));

export default useStyles;
