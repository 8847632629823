import PropTypes from 'prop-types';

// constants
import { textValues } from './constants';

// components
import JsonForm from '@/components/widgets/form';

// material
import { CheckCircle, Close, FilterAlt } from '@mui/icons-material';
import { Box, Button, Dialog, Drawer, Typography } from '@mui/material';

// utils
import { useSelector } from 'react-redux';

// style
import useStyles from './style';

export default function SearchFilter({ toggleModalOpen, ...props }) {
	const { classes, cx } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	let ModalComponent = Dialog,
		modalProps = {
			open: props.modalOpen,
			onClose: toggleModalOpen,
			classes: { paper: classes.dialogPaper },
			maxWidth: 'lg',
		};

	if (isMobile) {
		ModalComponent = Drawer;
		modalProps.anchor = 'right';
	}

	const renderModalHeader = () => (
		<Box className={classes.header}>
			<Box>
				<FilterAlt
					fontSize="medium"
					classes={{ root: classes.modalFilterIcon }}
				/>
				<Typography variant="h6" component="span">
					{textValues.filters}
				</Typography>
			</Box>
			<Typography
				className={cx(classes.clearFilterText, classes.cursorPointer)}
				onClick={props.handleClearFilter}
			>
				{textValues.clearAllFilters}
			</Typography>
		</Box>
	);

	const renderModalForm = () => (
		<Box>
			<JsonForm
				uiSchema={props.uiSchema}
				schema={props.controlSchema}
				data={props.formData}
				onChange={props.handleFilterChange}
			/>
			<Box className={classes.buttonGroup}>
				<Button
					variant="outlined"
					color="inherit"
					className={classes.button}
					onClick={toggleModalOpen}
				>
					{textValues.cancel}
				</Button>
				<Button
					variant="contained"
					color="success"
					className={classes.button}
					onClick={props.handleApplyFilter}
				>
					{textValues.applyFilter}
				</Button>
			</Box>
		</Box>
	);

	return (
		<>
			<Box className={classes.filterIconWrap}>
				{props.filterApplied ? (
					<>
						<FilterAlt
							fontSize={isMobile ? 'medium' : 'large'}
							classes={{
								root: cx(classes.appliedFilterIcon, classes.cursorPointer),
							}}
							onClick={toggleModalOpen}
						/>
						<CheckCircle classes={{ root: classes.appliedFilterSubIcon }} />
					</>
				) : (
					<FilterAlt
						fontSize={isMobile ? 'medium' : 'large'}
						classes={{ root: cx(classes.filterIcon, classes.cursorPointer) }}
						onClick={toggleModalOpen}
					/>
				)}
			</Box>

			<ModalComponent {...modalProps}>
				<Box className={classes.headerIconWrap}>
					<Close
						onClick={toggleModalOpen}
						classes={{ root: classes.cursorPointer }}
					/>
				</Box>
				{renderModalHeader()}
				{renderModalForm()}
			</ModalComponent>
		</>
	);
}

SearchFilter.propTypes = {
	filterApplied: PropTypes.bool,
	handleApplyFilter: PropTypes.func.isRequired,
	handleClearFilter: PropTypes.func.isRequired,
	formData: PropTypes.object,
	handleFilterChange: PropTypes.func.isRequired,
	uiSchema: PropTypes.object.isRequired,
	controlSchema: PropTypes.object.isRequired,
	modalOpen: PropTypes.bool,
	toggleModalOpen: PropTypes.func,
};

SearchFilter.defaultProps = {
	filterApplied: false,
	modalOpen: false,
};
