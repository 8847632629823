import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {
		border: (props) => {
			const color =
				props.color === 'secondary'
					? theme.palette.secondary.main
					: props.color === 'success'
					? theme.palette.success.main
					: props.color === 'primary'
					? theme.palette.primary.main
					: theme.palette.grey[600];
			if (props.variant === 'outlined') {
				return `1px solid ${color}`;
			} else return 'none';
		},
		color: (props) => {
			const color =
				props.color === 'secondary'
					? theme.palette.secondary.main
					: props.color === 'success'
					? theme.palette.success.main
					: props.color === 'primary'
					? theme.palette.primary.main
					: theme.palette.grey[600];
			if (props.variant === 'filled') {
				return theme.palette.common.white;
			} else return color;
		},
		backgroundColor: (props) => {
			const color =
				props.color === 'secondary'
					? theme.palette.secondary.main
					: props.color === 'success'
					? theme.palette.success.main
					: props.color === 'primary'
					? theme.palette.primary.main
					: theme.palette.grey[600];

			if (props.variant === 'filled') {
				return color;
			} else return theme.palette.background.white;
		},
		'&:hover': {
			backgroundColor: (props) => {
				const color =
					props.color === 'secondary'
						? theme.palette.secondary.dark
						: props.color === 'success'
						? theme.palette.success.dark
						: props.color === 'primary'
						? theme.palette.primary.dark
						: theme.palette.grey[700];
				if (props.variant === 'filled') {
					return color;
				} else return theme.palette.background.grey;
			},
		},
	},
}));

export default useStyles;
