import PropTypes from 'prop-types';

// material
import { Public } from '@mui/icons-material';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
// utils
import { useSelector } from 'react-redux';

// style
import useStyles from './style';

export default function SearchDropdown(props) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	return (
		<Box className={classes.searchDropdownWrap}>
			{!isMobile && (
				<Box className={classes.iconWrap}>
					<Public classes={{ root: classes.icon }} />
				</Box>
			)}
			<Box>
				<FormControl variant="standard" className={classes.dropdownWrap}>
					<Select
						value={props.value}
						onChange={props.handleChange}
						disableUnderline
						disabled={props.disabled}
						displayEmpty
						classes={{ select: classes.select }}
						MenuProps={{ classes: { paper: classes.selectMenuPaper } }}
					>
						<MenuItem value="">All</MenuItem>
						{props.options.map((option, index) => (
							<MenuItem key={index} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</Box>
	);
}

SearchDropdown.propTypes = {
	handleChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	value: PropTypes.string,
	options: PropTypes.array,
};

SearchDropdown.defaultProps = {
	value: '',
	disabled: false,
	options: [],
};
